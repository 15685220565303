<template>
  <div class="text-center d-flex justify-center items-center full-height">
    <h2>
      Página não encontrada.
    </h2>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
